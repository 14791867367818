import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Drawer from "../components/Drawer";
import './NotFound.css'

const Loading = () => {
const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Agrega un retraso de 1 segundo (1000 ms) antes de hacer fadeIn
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 60000);

    // Limpia el timeout en caso de desmontaje del componente
    return () => clearTimeout(timeout);
  }, []);
    return (
    <div>
      <Header white/>
      <Drawer />
      <div className="absolute-music-container"></div>
      <div className={`notfound-container ${
        fadeIn ? 'show-notfound' : ''
      }`}>
        <h2 className="notfound-title">Sorry the page could not be found</h2>
        <div style={{height: 50}} />
        <h3 className="notfound-subtitle">This page could not be found</h3>
        <a href="/" className="notfound-back">Back to the home page</a>
      </div>
      
    </div>
  );
};

export default Loading;
