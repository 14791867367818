import React, { useState } from "react";
import "./Director.css";
import { Link } from 'react-router-dom';

const Director = ({ link, name, imageURL, id}) => {
  const [showImage, setShowImage] = useState(false);

  // const toggleImage = () => {
  //   setShowImage(!showImage);
  // };
  return (
    <>
      <Link
        className="name-container"
        to={"/directors/"+ id+ "/" + link}
        onMouseEnter={() => setShowImage(true)}
        onMouseLeave={() => setShowImage(false)}
      >
        <h2
          className="name uppercase"
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}
        >
          {name}
        </h2>
        <h2 className="border-name uppercase">{name}</h2>
      </Link>

      <div className={`image-overlay ${showImage ? "show" : ""}`}>
        <div className="image-container">
          <img className="image" src={imageURL} alt={name} />
        </div>
      </div>
    </>
  );
};

export default Director;
