import React, { useState } from "react";
import "./DirectorForm.css";
import axios from "axios";
import { apiRoute } from "../../services/api";

const EditMainVideo = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Manejar la carga de la imagen
  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append("videoFile", file);
    if (!file) {
      alert("Por favor coloque un video");
      setLoading(false)
    } else {
      try {
        // Realiza la solicitud HTTP POST al servidor para crear un nuevo director

        const response = await axios.patch(apiRoute + "mainVideo", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Indica que estás enviando datos multipart/form-data
          },
        });

        if (response.status === 200) {
          // El director fue creado exitosamente
          console.log("Video agregado exitosamente", response.data);
          alert("El video a sido editado Exitosamente");
          setFile(null);
          setLoading(false)
          // Aquí puedes redirigir al usuario a una página de éxito o realizar otras acciones.
        }
      } catch (error) {
        // Maneja los errores en caso de que la solicitud falle
        console.error("Error al agregar video:", error);
        alert("No se ah podido editar el video, intentelo de nuevo mas tarde");
        setLoading(false)
      }
    }
  };
  return (
    <div className="director-form-container">
      <h2 className="form-title">Cambiar Video Principal</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="image" className="form-label">
            Video:
          </label>
          <input
            type="file"
            id="video"
            accept="video/*"
            name="videoFile"
            className="form-input"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-actions">
          {loading ? (
            <button className="form-button" disabled>Loading...</button>
          ) : (
            <button type="submit" className="form-button">
              Cambiar video principal
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default EditMainVideo;
