import React, { useState, useEffect } from "react";
import "./Settings.css";
import Logo from "../assets/logo/logoWhite.png";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../services/firebase";
// import AddMusicVideo from "../components/settings/AddMusicVideo";
import AddDirectorForm from "../components/settings/DirectorForm";
import EditDirectorForm from "../components/settings/EditDirectorForm";
import EditMainVideo from "../components/settings/EditMainVideo";
import AddMusicVideo from "../components/settings/AddMusicVideo";
import EditMusicVideo from "../components/settings/EditMusicVideo";
import DeleteVideo from "../components/settings/DeleteVideo";
import DeleteDirector from "../components/settings/DeleteDirector";

const Settings = () => {
  const navigate = useNavigate();
  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("sign out successful");
        navigate("/admin");
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };
  const [crearNuevoDirector, setCrearNuevoDirector] = useState(false);
  const [editarDirector, setEditarDirector] = useState(false);
  const [deleteDirector, setDeleteDirector] = useState(false)
  const [agregarVideoMusical, setAgregarVideoMusical] = useState(false);
  const [editarVideoMusical, setEditarVideoMusical] = useState(false);
  const [deleteVideo, setDeleteVideo] = useState(false)
  const [editarMainVideo, setEditarMainVideo] = useState(false)

  // Funciones para cambiar el estado de cada elemento
  const handleClickCrearNuevoDirector = () => {
    setCrearNuevoDirector(true);
    setEditarDirector(false);
    setAgregarVideoMusical(false);
    setEditarVideoMusical(false);
    setEditarMainVideo(false);
    setDeleteDirector(false);
    setDeleteVideo(false);
  };

  const handleClickEditarDirector = () => {
    setCrearNuevoDirector(false);
    setEditarDirector(true);
    setAgregarVideoMusical(false);
    setEditarMainVideo(false);
    setEditarVideoMusical(false);
    setDeleteDirector(false);
    setDeleteVideo(false);
  };

  const handleClickAgregarVideoMusical = () => {
    setCrearNuevoDirector(false);
    setEditarDirector(false);
    setAgregarVideoMusical(true);
    setEditarMainVideo(false);
    setEditarVideoMusical(false);
    setDeleteDirector(false);
    setDeleteVideo(false);
  };

  const handleClickEditarVideoMusical = () => {
    setCrearNuevoDirector(false);
    setEditarDirector(false);
    setAgregarVideoMusical(false);
    setEditarMainVideo(false);
    setEditarVideoMusical(true);
    setDeleteDirector(false);
    setDeleteVideo(false);
  };

  const handleClickEditarMainVideo = () => {
    setCrearNuevoDirector(false);
    setEditarDirector(false);
    setAgregarVideoMusical(false);
    setEditarMainVideo(true);
    setEditarVideoMusical(false);
    setDeleteDirector(false);
    setDeleteVideo(false);
  }

  const handleClickDeleteVideo = () => {
    setCrearNuevoDirector(false);
    setEditarDirector(false);
    setAgregarVideoMusical(false);
    setEditarMainVideo(false);
    setEditarVideoMusical(false);
    setDeleteDirector(false);
    setDeleteVideo(true);
  }
  const handleClickDeleteDirector = () => {
    setCrearNuevoDirector(false);
    setEditarDirector(false);
    setAgregarVideoMusical(false);
    setEditarMainVideo(false);
    setEditarVideoMusical(false);
    setDeleteDirector(true);
    setDeleteVideo(false);
  }

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="invisible-logout-button">Cerrar Sesión</div>
        <img style={{ height: "8vh" }} src={Logo} alt="logo" />
        <button className="logout-button" onClick={userSignOut}>
          Cerrar Sesión
        </button>
      </div>
      <div className="settings-sidevar">
        <div>
          <ul className="settings-selector">
            <li
              onClick={handleClickCrearNuevoDirector}
              className={crearNuevoDirector ? "bold-text" : ""}
            >
              Crear Director
            </li>
            <li
              onClick={handleClickEditarDirector}
              className={editarDirector ? "bold-text" : ""}
            >
              Editar Director
            </li>
            <li
              onClick={handleClickDeleteDirector}
              className={deleteDirector ? "bold-text" : ""}
            >
              Borrar Director
            </li>
            <li
              onClick={handleClickAgregarVideoMusical}
              className={agregarVideoMusical ? "bold-text" : ""}
            >
              Agregar Video Musical
            </li>
            <li
              onClick={handleClickEditarVideoMusical}
              className={editarVideoMusical ? "bold-text" : ""}
            >
              Editar Video Musical
            </li>
            <li
              onClick={handleClickDeleteVideo}
              className={deleteVideo ? "bold-text" : ""}
            >
              Borrar Video Musical
            </li>
            <li
              onClick={handleClickEditarMainVideo}
              className={editarMainVideo ? "bold-text" : ""}
            >
              Editar Video Principal
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{
          paddingTop: "10vh",
          paddingLeft: "20vw",
          display: "flex",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {crearNuevoDirector ? <AddDirectorForm /> : null}
        {editarDirector ? <EditDirectorForm /> : null}
        {deleteDirector ? <DeleteDirector /> : null}
        {agregarVideoMusical ? <AddMusicVideo/> : null}
        {editarVideoMusical ? <EditMusicVideo/> : null}
        {deleteVideo ? <DeleteVideo/> : null}
        {editarMainVideo ? <EditMainVideo /> : null }


      </div>
    </div>
  );
};

export default Settings;
