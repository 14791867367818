import React, { useEffect, useState } from 'react';
import "./DirectorPage.css";
import Header from "./Header.jsx";
import Drawer from "./Drawer";
import Navbar from "./navbar";

const DirectorPage = ({ director }) => {
  
  const name = director.name;
  const size = 90/name.length
  const [font, setFont] = useState(11)

useEffect(() => {
  
if (size < 11) {
    setFont(size)
  }

}, [size])
  
  return (
    <div>
      <Header white></Header>
      <Drawer />
      <div className="absolute-music-container"></div>
      <div className="director-page-background">
        <h2 className="director-page-name uppercase" style={{fontSize: `${font}vw`}}>{name}</h2>
        <div className='separador'></div>
        <Navbar director={director} hideMusic />
      </div>
    </div>
  );
};

export default DirectorPage;
