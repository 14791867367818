import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import "./Reel.css";
import VimeoPlayer from '@vimeo/player';

const Reel = ({ director }) => {
  const name = director.name;
  const size = 65 / name.length;
  const [font, setFont] = useState(11);
  const playerRef = useRef(null);

  useEffect(() => {
    if (size < 9) {
      setFont(size);
    }
  }, [size]);

  useEffect(() => {
    console.log(director)
    const options = {
      id: director.reelID,
      // controls: true,
      loop: false,
      autoplay: false,
      responsive: true,
      outro: false,
    };

    playerRef.current = new VimeoPlayer('vimeo-player', options);

    const vimeoElement = document.querySelector('.vimeo-player-container');

    // // Toggle play/pause on video click
    // vimeoElement.addEventListener('click', () => {
    //   playerRef.current.getPaused().then(paused => {
    //     if (paused) {
    //       playerRef.current.play();
    //     } else {
    //       playerRef.current.pause();
    //     }
    //   });
    // });

    return () => {
      playerRef.current.destroy();
      // vimeoElement.removeEventListener('click', () => {});
    };
  }, [director]);

  return (
    <div
      className="reel-container"
    >
      <div className="black-background"></div>
      <Header white />
      <div className="reel-director uppercase" style={{fontSize: `${font}vw`}}>{name}</div>
      <div className="separador-video"></div>
      <div className="vimeo-player-container"
      >
        {/* <iframe
          src={director.reel}
          title={director.name}
          width={"100%"}
          height={"100%"}
          frameborder="0"
          allowfullscreen
          autoPlay
        > */}
          {/* <source src={director.reel} /> */}
        {/* </iframe> */}
        <div id="vimeo-player"></div>
      {/* <button onClick={handlePlayClick}>Play</button> */}
      </div>
    </div>
  );
};

export default Reel;
