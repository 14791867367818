import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Drawer from "../components/Drawer";
import Navbar from "../components/navbar";
import Cards2 from "../components/Cards2";
import './MusicVideos.css'

const MusicVideo = ({ director }) => {
  const slides = [
    {
      id: 0,
      image:
        "https://api.londonalley.com/wp-content/uploads/2021/07/LilNasX_IndustryBaby_Gif_STILL-1.jpg",
      video:
        "https://player.vimeo.com/external/578290378.hd.mp4?s=3b7263e804398920566990d0e84dbc5a7a4c2f87&profile_id=175",
      title: "Lil Nas X • Jack Harlow",
      subTitle: "Industry Baby",
      director: "Christian Breslauer",
    },
    {
      id: 1,
      image:
        "https://api.londonalley.com/wp-content/uploads/2023/06/BARBIE-SLIDER-STILL-1.jpg",
      video:
        "https://player.vimeo.com/progressive_redirect/playback/840199338/rendition/1080p/file.mp4?loc=external&signature=8907c918ad78c5d6857f49ed67154a443d8aee07b4ddd73add0b71def8515b47",
      title: "Nicki Minaj • Ice Spice",
      subTitle: "Barbie World",
      director: "Hannah Lux Davis",
    },
    {
      id: 2,
      image:
        "https://api.londonalley.com/wp-content/uploads/2023/04/Screen-Shot-2023-04-17-at-12.05.52-PM-1.jpg",
      video:
        "https://player.vimeo.com/progressive_redirect/playback/839191619/rendition/1080p/file.mp4?loc=external&signature=e4c1cffd6add8762c8c3fa91f98cc7057dec8664ed514271e0a2b91a82090234",
      title: "Illinois Lotto",
      subTitle: "Get It Director's Cut",
      director: "Courtney Phillips",
    },
    {
      id: 3,
      image:
        "https://api.londonalley.com/wp-content/uploads/2023/06/MOTOROLA_SLIDER_STILL-1.jpg",
      video:
        "https://player.vimeo.com/progressive_redirect/playback/840216207/rendition/1080p/file.mp4?loc=external&signature=31d423faafb897eecb300cdae963be40f3eab2b0cadecd98f25995fb7ef6ee6d",
      title: "Motorola",
      subTitle: "Flip The Script",
      director: "Courtney Phillips",
    },
  ];
  const name = director.name;
  const size = 65 / name.length;
  const [font, setFont] = useState(11);
  const musicVideos = director.musicVideos

  useEffect(() => {
    if (size < 9) {
      setFont(size);
    }
  }, [size]);

  return (
    <div>
      <div className="absolute-music-container"></div>
      <Header white />
      <Drawer />
      <div style={{ height: 60 }} />
 
        <div
          className="music-video-director uppercase"
          style={{ fontSize: `${font}vw` }}
        >
          {name}
        </div>
 
      <div style={{ height: 60 }} />
      <div className="cards-container">
        {musicVideos.reverse().map((data)=>(
        <Cards2 video={data} />
        ))}
        </div>
    </div>
  );
};

export default MusicVideo;
