import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Contact from "./pages/contact";
import Directors from "./pages/directors";
import Work from "./pages/work";
import NotFound from "./pages/NotFound";
import Advertising from "./pages/Advertising";
import MusicVideo from "./pages/MusicVideo";
import Content from "./pages/Content";
import About from "./pages/about";
import DirectorPage from "./components/DirectorPage";
// import data from "./components/data";
import Reel from "./pages/Reel";
import { apiRoute } from "./services/api";
// import firebase from 'firebase/app';
import axios from "axios";
import Login from "./pages/LogIn";
import Settings from "./pages/Settings";
// import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/firebase";
import AddMusicVideo from "./components/settings/AddMusicVideo";
import DirectorForm from "./components/settings/DirectorForm";
import Test from "./pages/Test";
// import data from "./components/data";
import SelectedVideo from "./pages/SelectedVideo";
import Loading from "./pages/Loading";

const App = () => {
  const [data, setData] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const actualUser = auth.currentUser;

  useEffect(() => {
    // Cuando el componente se monta, obtén la lista de directores
    const fetchData = async () => {
      try {
        const response1 = await axios.get(apiRoute + "directors");
        const response2 = await axios.get(apiRoute + "videos/get");

        const directoresConVideos = response1.data.map((director) => {
          // Filtrar los musicVideos que tienen el mismo director_id que el id del director
          const videosDelDirector = response2.data.filter(
            (video) => video.director_id === director.id
          );
        
          // Crear un nuevo objeto director que incluye los videos como musicVideos
          return {
            ...director,
            musicVideos: videosDelDirector,
          };
        });
        setData(directoresConVideos);
        console.log(directoresConVideos)
      } catch (error) {
        console.error("Error al obtener la lista de directores:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (actualUser) {
      setAuthUser(actualUser);
    } else {
      setAuthUser(null);
    }
  }, [actualUser]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Work />} />
        <Route
          path="/admin"
          element={actualUser ? <Navigate to="/admin/settings" /> : <Login />}
        />
        <Route
          path="/admin/settings"
          element={actualUser ? <Settings /> : <Navigate to="/admin" />}
        />

        <Route path="/work/filter/advertising" element={<Advertising />} />
        <Route path="/work/filter/music-video" element={<MusicVideo />} />
        <Route path="/work/filter/content" element={<Content />} />
        <Route path="/directors" element={<Directors />} />
        {data.map((director) => (
          <Route
            key={director.id}
            path={`/directors/${director.id}/${director.link}`}
            element={<DirectorPage director={director} />}
          >
          </Route>
        ))}
        {data.map((director) => (
          <Route
            path={`/directors/${director.id}/${director.link}/music-videos`}
            element={<MusicVideo director={director} />}
          />
        ))}
       
          <Route
            path={`/directors/*`}
            element={<Loading />}
          />
 
        {data.map((director) =>
          director.musicVideos && Array.isArray(director.musicVideos)
            ? director.musicVideos.map((video) => (
                <Route
                  key={video.id}
                  path={`/directors/${director.id}/${director.link}/music-videos/${video.id}`}
                  element={<SelectedVideo director={director} video={video} />}
                />
              ))
            : null
        )}
        {data.map((director) => (
          <Route
            path={`/directors/${director.id}/${director.link}/reel`}
            element={<Reel director={director} />}
          />
        ))}
        {data.map((director) => (
          <Route
            path={`/directors/${director.id}/${director.link}/about`}
            element={<About director={director} />}
          />
        ))}
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/test" element={<Test />} /> */}

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
