import React, { useEffect, useState } from "react";
import "./DirectorForm.css";
import axios from "axios";
import { apiRoute } from "../../services/api";

function AddMusicVideo({ director }) {
  const [videoData, setVideoData] = useState({
    director_id: null,
    thumbnail: null,
    videoPreview: null, // Para manejar la carga de la imagen
    youtubeLink: "",
    title: "",
    client: "",
    year: "",
    credits: "",
  });
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [directorsList, setDirectorsList] = useState([]);
  const [selectedDirector, setSelectedDirector] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadDirectorsList = async () => {
    try {
      const response = await axios.get(apiRoute + "directors");
      setDirectorsList(response.data);
    } catch (error) {
      console.error("Error al obtener la lista de directores:", error);
    }
  };

  useEffect(() => {
    loadDirectorsList();
  }, [selectedDirector]);

  const handleDirectorSelect = (director) => {
    setSelectedDirector(director);
  };

  // Manejar cambios en los campos del formulario
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVideoData({
      ...videoData,
      [name]: value,
    });
  };

  // Manejar la carga de la imagen
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };
  const handleVideoChange = (event) => {
    setVideo(event.target.files[0]);
  };

  // Manejar el envío del formulario
  // Manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    // Crear un objeto FormData
    const formData = new FormData();
    // Agregar cada campo de videoData al formData
    formData.append("director_id", selectedDirector.id);
    formData.append("thumbnail", image);
    formData.append("videoPreview", video);
    formData.append("youtubeLink", videoData.youtubeLink);
    formData.append("title", videoData.title);
    formData.append("client", videoData.client);
    formData.append("year", videoData.year);
    formData.append("credits", videoData.credits);

    // Ahora puedes usar formData para enviar una solicitud HTTP

    if (
      videoData.youtubeLink === "" ||
      !image ||
      !video ||
      videoData.title === ""
    ) {
      alert("Por favor rellena todos los campos antes de crear un director");
      setLoading(false)
    } else {
      try {
        // Realiza la solicitud HTTP POST al servidor para crear un nuevo director
        console.log(formData);
        const response = await axios.post(
          apiRoute + "videos/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Indica que estás enviando datos multipart/form-data
            },
          }
        );
        console.log(response)

        if (response.status === 201) {
          // El director fue creado exitosamente
          console.log("video creado exitosamente", response.data);
          alert('Video agregado exitosamente a ' + selectedDirector.name)
          setVideoData({
            director_id: null,
            thumbnail: null,
            videoPreview: null, // Para manejar la carga de la imagen
            youtubeLink: "",
            title: "",
            client: "",
            year: "",
            credits: "",
          });
          setSelectedDirector(null)
          setImage(null)
          setVideo(null)
          setLoading(false)

          // Aquí puedes redirigir al usuario a una página de éxito o realizar otras acciones.
        }
      } catch (error) {
        // Maneja los errores en caso de que la solicitud falle
        console.error("Error al crear Video:", error);
        console.log(selectedDirector, image, video, videoData)
        setLoading(false)
      }
    }
  };

  return (
    <>
      {selectedDirector ? (
        <div className="director-form-container">
          <h2 className="form-title">Crear Nuevo Video Musical</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="thumbnail" className="form-label">
                Thumbnail:
              </label>
              <input
                type="file"
                id="thumbnail"
                accept="image/*"
                name="thumbnail"
                className="form-input"
                onChange={handleImageChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="videoPreview" className="form-label">
                Video Preview:
              </label>
              <input
                type="file"
                id="videoPreview"
                accept="video/*"
                name="videoPreview"
                className="form-input"
                onChange={handleVideoChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="youtubeLink" className="form-label">
                Enlace de YouTube:
              </label>
              <input
                type="text"
                id="youtubeLink"
                name="youtubeLink"
                className="form-input"
                value={videoData.youtubeLink}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="title" className="form-label">
                Título:
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="form-input"
                value={videoData.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="client" className="form-label">
                Cliente:
              </label>
              <input
                type="text"
                id="client"
                name="client"
                className="form-input"
                value={videoData.client}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="year" className="form-label">
                Año:
              </label>
              <input
                type="number"
                id="year"
                name="year"
                className="form-input"
                value={videoData.year}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
            <label htmlFor="credits" className="form-label">
            Creditos:
          </label>
          <textarea
            id="credits"
            name="credits"
            className="form-input"
            value={videoData.credits}
            onChange={handleInputChange}
          />
            </div>
            
            {/* Puedes agregar más campos aquí según tus necesidades */}
            <div className="form-actions">
            {loading ? (
                <button className="form-button" disabled>Loading...</button>
              ) : (
              <button type="submit" className="form-button">
                Crear Video
              </button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="director-form-container">
          <h2 className="form-title">Seleccione al director del video a agregar</h2>
          <ul>
            {directorsList &&
              directorsList.map((director) => {
                return (
                  <li
                    className="select-director"
                    key={`director-${director.id}`}
                    onClick={() => handleDirectorSelect(director)}
                  >
                    {director.name}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}

export default AddMusicVideo;
