import React from "react";
import "./Navbar.css";
import { Link } from 'react-router-dom';

const Navbar = ({ director }) => {
  return (
    <div className="navbar-container">
      <div className="navbar-subContainer">
        {director.musicVideos.length !== 0 ? (
          <Link
            className="navbar-item"
            to={`/directors/${director.id}/${director.link}/music-videos`}
          >
            <h2 className="navbar-link uppercase">Music Videos</h2>
          </Link>
        ) : null}
        {director.reelID ? (<Link className="navbar-item" to={`/directors/${director.id}/${director.link}/reel`}>
          <h2 className="navbar-link uppercase">Reel</h2>
        </Link>
        ): null}
        <Link className="navbar-item" to={`/directors/${director.id}/${director.link}/about`}>
          <h2 className="navbar-link uppercase">About</h2>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
