import React, { useState, useEffect } from "react";
import './MainVideo.css'; // Importa el archivo de estilos CSS
// import VideoPrincipal from '../assets/videoPrincipal/SSF_1.mp4'
import { apiRoute } from '../services/api';
import axios from "axios";

function MainVideo() {

  // const [videoPrincipal, setVideoPrincipal] = useState(null)
  // useEffect(() => {
  //   // Cuando el componente se monta, obtén la lista de directores
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(apiRoute + "mainVideo/1/videoFile");
  //       setVideoPrincipal(response);
  //     } catch (error) {
  //       console.error("Error al obtener la lista de directores:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const videoPrincipal = apiRoute + "mainVideo/1/videoFile"

  return (
    <div className="video-main-container">
      <video className='video-main'  muted playsInline autoPlay loop>
        <source src={videoPrincipal} type="video/mp4" />
        Tu navegador no admite el elemento de video.
      </video>
    </div>
  );
}

export default MainVideo;