import React, { useEffect, useState } from "react";
import "./DirectorForm.css";
import axios from "axios";
import { apiRoute } from "../../services/api";

function DeleteVideo({ director }) {
  const [videoData, setVideoData] = useState({
    id: null,
    director_id: null,
    thumbnail: null,
    videoPreview: null, // Para manejar la carga de la imagen
    youtubeLink: "",
    title: "",
    client: "",
    year: "",
    producer: "",
    productionManager: "",
    photography: "",
    art: "",
    edit: "",
    colorGrading: "",
    AR: "",
    ARVideo: "",
    selloDiscografico: "",
  });
  const [directorsList, setDirectorsList] = useState([]);
  const [selectedDirector, setSelectedDirector] = useState([]);
  const [videoList, setVideoList] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  // const [showDirectors, setShowDirectors] = useState(true)
  const [seleccioneDirector, setSeleccioneDirector] = useState(true);
  const [seleccioneVideo, setseleccioneVideo] = useState(false);
  const [reload, setReload] = useState(false);

  const loadDirectorsList = async () => {
    try {
      const response = await axios.get(apiRoute + "directors");
      setDirectorsList(response.data);
    } catch (error) {
      console.error("Error al obtener la lista de directores:", error);
    }
  };

  const loadVideoList = async () => {
    try {
      const response = await axios.get(apiRoute + "videos/get");
      setVideoList(response.data);
      console.log("loadVideoList", response.data);
    } catch (error) {
      console.error("Error al obtener la lista de directores:", error);
    }
  };

  useEffect(() => {
    loadDirectorsList();
    loadVideoList();
  }, []);

  const handleDirectorSelect = (director) => {
    if (videoList) {
      setSelectedDirector(director);
      setSeleccioneDirector(false);
      setseleccioneVideo(true);
    }
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setseleccioneVideo(false);
    setVideoData({
      id: video.id,
      director_id: selectedDirector.id,
      thumbnail: null,
      videoPreview: null, // Para manejar la carga de la imagen
      youtubeLink: video.youtubeLink,
      title: video.title,
      client: video.client,
      year: video.year,
      producer: video.producer,
      productionManager: video.productionManager,
      photography: video.photography,
      art: video.art,
      edit: video.edit,
      colorGrading: video.colorGrading,
      AR: video.AR,
      ARVideo: video.ARVideo,
      selloDiscografico: video.selloDiscografico,
    });

    console.log(video);
  };

  const handleDelete = async () => {
    try {
      // Realiza la solicitud HTTP POST al servidor para crear un nuevo director
      const response = await axios.delete(
        apiRoute + "videos/" + videoData.id + "/delete"
      );
        console.log(response)
      if (response.status === 200) {
        // El director fue creado exitosamente
        console.log("Director creado exitosamente", response.data);
        alert("El video a sido borrado Exitosamente");
        setReload(!reload);
        setVideoData({
          id: null,
          director_id: null,
          thumbnail: null,
          videoPreview: null, // Para manejar la carga de la imagen
          youtubeLink: "",
          title: "",
          client: "",
          year: "",
          producer: "",
          productionManager: "",
          photography: "",
          art: "",
          edit: "",
          colorGrading: "",
          AR: "",
          ARVideo: "",
          selloDiscografico: "",
        });
        setSelectedDirector([]);
        setSeleccioneDirector(true);
        setSelectedVideo(null);
        loadVideoList();
        // Aquí puedes redirigir al usuario a una página de éxito o realizar otras acciones.
      }
    } catch (error) {
      // Maneja los errores en caso de que la solicitud falle
      console.error("Error al crear Video:", error);
      alert("No se ah podido editar el video, intentelo de nuevo mas tarde");
      console.log(selectedDirector, videoData);
    }
  };

  return (
    <>
      {selectedVideo ? (
        <div className="director-form-container">
          <h2 className="form-title">
            Desea eliminar el video {videoData.title} del director{" "}
            {selectedDirector.name}?
          </h2>

          {/* Puedes agregar más campos aquí según tus necesidades */}
          <div className="form-actions">
            <button
              className="form-button form-delete"
              style={{ backgroundColor: "#df0b0b" }}
              onClick={handleDelete}
            >
              Eliminar Video
            </button>
          </div>
        </div>
      ) : null}

      {seleccioneDirector ? (
        <div className="director-form-container">
          <h2 className="form-title">Seleccione un director para borrar un video</h2>
          <ul>
            {directorsList.map((director) => {
              return (
                <li
                  className="select-director"
                  key={`director-${director.id}`}
                  onClick={() => handleDirectorSelect(director)}
                >
                  {director.name}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {seleccioneVideo ? (
        <div className="director-form-container">
          <h2 className="form-title">Seleccione un video para borrar</h2>
          <ul>
            {videoList.map((video) => {
              if (video.director_id === selectedDirector.id) {
                return (
                  <li
                    className="select-director"
                    key={video.id}
                    onClick={() => handleVideoSelect(video)}
                  >
                    {video.title}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
}

export default DeleteVideo;
