import React, { useState } from "react";
import "./DirectorForm.css";
import axios from "axios";
import { apiRoute } from "../../services/api";

function AddDirectorForm() {
  const [directorData, setDirectorData] = useState({
    name: "",
    link: "",
    image: null, // Para manejar la carga de la imagen
    reelID: "",
    about: "",
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Manejar cambios en los campos del formulario
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDirectorData({
      ...directorData,
      [name]: value,
    });
  };

  // Generar el enlace automáticamente
  const generateLink = () => {
    const formattedName = directorData.name.toLowerCase().replace(/ /g, "-");
    setDirectorData({
      ...directorData,
      link: formattedName,
    });
  };

  // Manejar la carga de la imagen
  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
   
  };

  // Manejar el envío del formulario
  // Manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", directorData.name);
    formData.append("link", directorData.link);
    formData.append("reelID", directorData.reelID);
    formData.append("about", directorData.about);
    if (
      directorData.name === "" ||
      !file ||
      directorData.about === ""
    ) {
      alert('Por favor rellena todos los campos antes de crear un director');
      setLoading(false)
    } else {
      try {
        // Realiza la solicitud HTTP POST al servidor para crear un nuevo director

        const response = await axios.post(
          apiRoute + "directors/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Indica que estás enviando datos multipart/form-data
            },
          }
        );

        if (response.status === 200) {
          // El director fue creado exitosamente
          console.log("Director creado exitosamente", response.data);
          alert("Director creado exitosamente")
          setDirectorData({
            name: "",
            link: "",
            image: null, // Para manejar la carga de la imagen
            reelID: "",
            about: "",
          });
          setFile(null)
          setLoading(false)

          // Aquí puedes redirigir al usuario a una página de éxito o realizar otras acciones.
        }
      } catch (error) {
        // Maneja los errores en caso de que la solicitud falle
        console.error("Error al crear director:", error);
        alert("Error al crear director intentelo de nuevo mas tarde")
        setLoading(false)
      }
    }
  };

  return (
    <div className="director-form-container">
      <h2 className="form-title">Crear Nuevo Director</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name" className="form-label">
            Nombre:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-input"
            value={directorData.name}
            onChange={handleInputChange}
            onBlur={generateLink}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image" className="form-label">
            Imagen:
          </label>
          <input
            type="file"
            id="image"
            accept="image/*"
            name="imageFile"
            className="form-input"
            onChange={handleImageChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reelID" className="form-label">
            Reel ID (Vimeo):
          </label>
          <input
            type="text"
            id="reelID"
            name="reelID"
            className="form-input"
            value={directorData.reelID}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="about" className="form-label">
            Biografía:
          </label>
          <textarea
            id="about"
            name="about"
            className="form-input"
            value={directorData.about}
            onChange={handleInputChange}
            maxLength={730} // Establece el límite máximo de caracteres
          />
          <div className="character-count">{directorData.about.length}/700</div>
        </div>
        <div className="form-actions">
        {loading ? (
                <button className="form-button" disabled>Loading...</button>
              ) : (
          <button type="submit" className="form-button">
            Crear Director
          </button>
              )}
        </div>
      </form>
    </div>
  );
}

export default AddDirectorForm;
