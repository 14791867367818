import React, {useEffect, useState} from 'react'
import Director from '../components/Director'
import './directors.css'
import Header from '../components/Header'
import Drawer from '../components/Drawer'
import { apiRoute, getAllDirectors } from '../services/api'
import axios from 'axios';
// import Benny from '../assets/directors/Benny-Boom2796-2-scaled.jpg'
// import Carla from '../assets/directors/CARLADAUDEN.jpeg'
// import Christian from '../assets/directors/Christian_Breslauer-1-scaled.jpeg'
// import Colin from '../assets/directors/COLINTILLEY.jpg'
// import Courtney from '../assets/directors/COURTNEYPHILLIPS.jpeg'
// import Hannah from '../assets/directors/HANNAHLUXDAVIS.jpeg'
// import Jake from '../assets/directors/JAKEWILSON.jpeg'
// import James from '../assets/directors/JAMESLARESE.jpeg' 
// import Jaquel from '../assets/directors/JAQUELKNIGHT.webp'
// import Kyle from '../assets/directors/KYLENEWACHECK.jpeg'
// import Lakebell from '../assets/directors/LAKEBELL.jpg'
// import MorganCooper from '../assets/directors/MORGANCOOPER.jpeg'
// import Tusk from '../assets/directors/tusk.jpeg'
// import AndresIbañez from '../assets/directors/AndresIbañez.png'
// import TamaraAcuña from '../assets/directors/TamaraAcuña.png'
// import DennysRodriguez from '../assets/directors/DennysRodriguez.jpeg'
// import data from '../components/data'
// import data from '../components/data'

const Directors = () => {
  // const directors =[
  //   {id:1, name: 'Andres Ibañez', link: 'andres-ibañez', imageURL: AndresIbañez },
  //   {id:2, name: 'Tamara Acuña', link: 'tamara-acuña', imageURL: TamaraAcuña },
  //   {id:3, name: 'Dennys Rodriguez', link: 'dennys-rodriguez', imageURL: DennysRodriguez },
    
  // ]



  // const directors2 =[
  //   {id:1, name: 'BENNY BOOM', link: 'benny-boom', imageURL: Benny},
  //   {id:2, name: 'CARLA DAUDEN', link: 'carla-dauden', imageURL: 'https://api.londonalley.com/wp-content/uploads/2022/05/img20211016_00520962.jpg' },
  //   {id:3, name: 'CHRISTIAN BRESLAUER', link: 'christian-breslauer', imageURL: Christian },
  //   {id:4, name: 'COLIN TILLEY', link: 'colin-tilley', imageURL: Colin },
  //   {id:5, name: 'COURTNEY PHILLIPS', link: 'courtney-phillips', imageURL: 'https://api.londonalley.com/wp-content/uploads/2014/12/Court.jpg' },
  //   {id:6, name: 'HANNAH LUX DAVIS', link: 'hannah-lux-davis', imageURL: Hannah },
  //   {id:7, name: 'JAKE WILSON', link: 'jake-wilson', imageURL: Jake },
  //   {id:8, name: 'JAMES LARESE', link: 'james-larese', imageURL: James },
  //   {id:9, name: 'JAQUEL KNIGHT', link: 'jaquel-knight', imageURL: Jaquel },
  //   {id:10, name: 'KYLE NEWACHECK', link: 'kyle-newacheck', imageURL: Kyle },
  //   {id:11, name: 'LAKE BELL', link: 'lake-bell', imageURL: Lakebell },
  //   {id:12, name: 'MORGAN COOPER', link: 'morgan-cooper', imageURL: MorganCooper },
  //   {id:13, name: 'TUSK', link: 'tusk', imageURL: Tusk },
  // ]
  const [data, setData] = useState([]);

  useEffect(() => {
    // Cuando el componente se monta, obtén la lista de directores
    const fetchData = async () => {
      try {
        const response = await axios.get(apiRoute + 'directors');
        setData(response.data);
      } catch (error) {
        console.error('Error al obtener la lista de directores:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
    <div className='background'>
       <Drawer />
      <div className='background-color'/>
      <Header />
      <div className='separador-directors'/>
    <div className='background-text uppercase'>
      <span className='text uppercase' style={{ fontWeight:'bold'}}>DIRECTORS</span>
    </div>
    {data.map((option)=>(
      <Director key={option.id} name={option.name} link={option.link} id={option.id} imageURL={apiRoute + `/directors/${option.id}/imagen`} />
      ))}
    <div className='separador-directors'/>
    </div>
   
    </>
  )
}

export default Directors;