import React, { useEffect, useState } from "react";
import "./SelectedVideo.css";
import ReactPlayer from "react-player/youtube";
import Header from "../components/Header";
import Drawer from "../components/Drawer";
import { apiRoute } from "../services/api";

const SelectedVideo = ({ director, video }) => {
  const name = director.name;
  const size = 90 / name.length;
  const [font, setFont] = useState(11);

  useEffect(() => {
    if (size < 11) {
      setFont(size);
    }
  }, [size]);
  return (
    <div className="selected-video-container">
      <div className="absolute-music-container"></div>
      <Header />
      <Drawer />
      <div className="video-preview-container uppercase">
        <video
          src={apiRoute + "/videos/" + video.id + "/videoPreview"}
          autoPlay
          playsInline
          loop
          muted
          type="video/mp4"
          className="video-preview"
        />
        <div className="video-text">
          <h2 style={{ fontSize: `${font}vw` }}>{video.title}</h2>
          <h3 className="video-client">{video.client}</h3>
        </div>
      </div>
      <div className="ficha-tecnica uppercase">
        <p>
          {video.title} - {video.client}
        </p>
        {video.year ? <p>Year: {video.year}</p> : null}
        <br />
        {video.credits ? (
          <>
            <p>Credits:</p>
            <pre className="credits uppercase">{video.credits}</pre>
          </>
        ) : null}
        {/* {video.productionManager ? <p>Production manager: {video.productionManager}</p> : null}
        {video.photography ? <p>Director of photography: {video.photography}</p> : null}
        {video.art ? <p>Art: {video.art}</p> : null}
        {video.edit ? <p>Edit: {video.edit}</p> : null}
        {video.colorGrading ? <p>Color Grading: {video.colorGrading}</p> : null}
        {video.AR ? (
          <>
            <br />
            <p>A&R: {video.AR}</p>
          </>
        ) : null}
        {video.ARVideo ? (
          <>
            <br />
            <p>A&R Video: {video.ARVideo}</p>
          </>
        ) : null}
        <br />
        <p>{video.selloDiscografico}</p> */}
      </div>
      <div className="video-youtube-container">
        <ReactPlayer
          url={video.youtubeLink}
          className="video-youtube"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};
export default SelectedVideo;
