import React from "react";

import Header from "../components/Header";
import Drawer from "../components/Drawer";
import Navbar from "../components/navbar";
import Cards from "../components/Cards";

const Content = () => {
  const slides = [
    {
      id: 0,
      image:
        "https://api.londonalley.com/wp-content/uploads/2023/04/Screen-Shot-2023-04-17-at-12.05.52-PM-1.jpg",
      video:
      "https://player.vimeo.com/progressive_redirect/playback/839191619/rendition/1080p/file.mp4?loc=external&signature=e4c1cffd6add8762c8c3fa91f98cc7057dec8664ed514271e0a2b91a82090234",
      title: "Illinois Lotto",
      subTitle: "Get It Director's Cut",
      director: "Courtney Phillips",
    },
    {
      id: 1,
      image:
      "https://api.londonalley.com/wp-content/uploads/2023/06/MOTOROLA_SLIDER_STILL-1.jpg",
      video:
      "https://player.vimeo.com/progressive_redirect/playback/840216207/rendition/1080p/file.mp4?loc=external&signature=31d423faafb897eecb300cdae963be40f3eab2b0cadecd98f25995fb7ef6ee6d",
      title: "Motorola",
      subTitle: "Flip The Script",
      director: "Courtney Phillips",
    },
    {
      id: 2,
      image:
        "https://api.londonalley.com/wp-content/uploads/2021/07/LilNasX_IndustryBaby_Gif_STILL-1.jpg",
      video:
        "https://player.vimeo.com/external/578290378.hd.mp4?s=3b7263e804398920566990d0e84dbc5a7a4c2f87&profile_id=175",
      title: "Lil Nas X • Jack Harlow",
      subTitle: "Industry Baby",
      director: "Christian Breslauer",
    },
    {
      id: 3,
      image:
        "https://api.londonalley.com/wp-content/uploads/2023/06/BARBIE-SLIDER-STILL-1.jpg",
      video:
        "https://player.vimeo.com/progressive_redirect/playback/840199338/rendition/1080p/file.mp4?loc=external&signature=8907c918ad78c5d6857f49ed67154a443d8aee07b4ddd73add0b71def8515b47",
      title: "Nicki Minaj • Ice Spice",
      subTitle: "Barbie World",
      director: "Hannah Lux Davis",
    },
    // { id: 4,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 5,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 6,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 7,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 8,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 9,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 10,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 11,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 12,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 13,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 14,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
    // { id: 15,
    //   image:"",
    //   video: "",
    //   title: "",
    //   subTitle:"",
    //   director:"",
    // },
  ];

  const leftGroup = [];
  const centerGroup = [];
  const rightGroup = [];

  for (let i = 0; i < slides.length; i += 3) {
    leftGroup.push(slides.slice(i, i + 3));
  }
  for (let i = 1; i < slides.length; i += 3) {
    centerGroup.push(slides.slice(i, i + 3));
  }
  for (let i = 2; i < slides.length; i += 3) {
    rightGroup.push(slides.slice(i, i + 3));
  }

  return (
    <div>
      <Header />
      <Drawer />
      <div style={{height: 60}} />
      <Navbar />
      <div style={{height: 60}} />
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
      <Cards videos={slides} align={'left'}/>
      <div>
        <div className="separador" />
      <Cards videos={slides} align={'right'}/>
      </div>
      </div>
    </div>
  );
};

export default Content;
