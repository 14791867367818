import React from "react";
import "./contact.css";
import logo from "../assets/logo/logoWhite.png";
import Drawer from "../components/Drawer";
import instagram from "../assets/iconos/instagram.png";
import correo from "../assets/iconos/correo.png";

const Contact = () => {
  return (
    <>
      <Drawer />
      <div className="contact-background">
        <img className="contact-logo" src={logo} alt="stereosonique" />
        <div style={{ height: "2vw" }} />
        <div className="contact-social-media">
          <a href="https://www.instagram.com/stereo.sonique/" target="_blank">
            <img
              className="social-media-logo"
              src={instagram}
              alt="instagram"
            />
          </a>
          <div className="social-media-logo-separator"></div>
          <a href="mailto:info@stereosoniquefilms.com">
            <img className="social-media-logo" src={correo} alt="correo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;
