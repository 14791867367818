import React from "react";
import { Link } from 'react-router-dom'; // Importa el componente Link
import LogoBlack from '../assets/logo/logoBlack.png';
import LogoWhite from '../assets/logo/logoWhite.png';
import './Header.css';

const Header = ({black}) => {
  
  return (
    <div className="header">
      {/* Envuelve la imagen con un enlace a la página de inicio */}
      <Link to="/">
        <img className={black ? "logo-black" : "logo-white"} src={black ? LogoBlack : LogoWhite} alt="Stereo Sonique" />
      </Link>
    </div>
  );
};

export default Header;
