import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB49N2kDHLpbsgZHs2xtgIFlx1iAXfwGrQ",
  authDomain: "stereosonique-63d95.firebaseapp.com",
  projectId: "stereosonique-63d95",
  storageBucket: "stereosonique-63d95.appspot.com",
  messagingSenderId: "268780409654",
  appId: "1:268780409654:web:5d3e0b2c83a7cb18b81997"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);