import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Drawer from "../components/Drawer";
import BackgroundText from "../components/BackbroundText";
import Abajo from "../assets/iconos/flecha-abajo.webp";
import "./about.css";
import { apiRoute } from "../services/api";

const About = ({ director }) => {
  const scrollToDiv = () => {
    const targetDiv = document.getElementById("targetDiv");
    if (targetDiv) {
      targetDiv.scrollIntoView({ 
        behavior: "smooth",
      });
    }
  };
  const paragraphs = director.about;
  const name = director.name;
  const size = 90 / name.length;
  const [font, setFont] = useState(11);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (size < 11) {
      setFont(size);
    }
  }, [size]);

  useEffect(() => {
    // Agrega un retraso de 1 segundo (1000 ms) antes de hacer fadeIn
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 1000);

    // Limpia el timeout en caso de desmontaje del componente
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      <Header />
      <Drawer />
      <BackgroundText text={"ABOUT"} />
      <div
       
        className="about-title-container"
      >
        <div style={{ height: "20vh" }}></div>
        <div
          className={`about-director-name uppercase ${
            fadeIn ? "about-director-name-visible" : ""
          }`}
          style={{
            fontSize: `${font}vw`,
          }}
        >
          {director.name}
        </div>
        <div onClick={scrollToDiv}>
          <img
            src={Abajo}
            alt="Flecha hacia abajo"
            style={{ height: "20vh" }}
          />
        </div>
      </div>
      <div
        id="targetDiv"
        className="about-bio-container"
      >
        <div className="about-img-container">
          <div className="about-media-separador"></div>
        <img src={apiRoute + `/directors/${director.id}/imagen`} alt="about-img" className="about-img" />
        </div>
        <div className="about-text">
          {/* {paragraphs.map((paragraph, index) => ( */}
            <p >{paragraphs}</p>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

export default About;
