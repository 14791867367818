// import React, { useState } from "react";
// import "./Cards.css";

// const Cards = ({ slides, bigCard, textAlign }) => {
//   const [onFocus, setOnFocus] = useState(false);
//   return (
//     <div>
//       <div className="card-blur"></div>
//       <a
//         className={bigCard ? "card-container-big" : "card-container"}
//         href="/"
//         onMouseEnter={() => console.log(slides.image)}
//         onMouseLeave={() => setOnFocus(false)}
//       >
//         <img src={slides.image} alt='imagen' />
//         <video src={slides.video} />
//       </a>
//     </div>
//   );
// };
// export default Cards;

import React, { useState } from "react";
import "./Cards.css"; // Asegúrate de ajustar la ruta al archivo CSS

const Cards = ({ videos, align }) => {
  const [hoveredVideo, setHoveredVideo] = useState(null);
  const handleVideoHover = (videoId) => {
    setHoveredVideo(videoId);
  }; 

  const handleVideoLeave = () => {
    setHoveredVideo(null);
  };

  return (
    <div className="video-list">
      {/* {hoveredVideo ? <div className="hovered-selection"></div> : null} */}
      {videos.map((video) => (
        <>
          <div key={video.id} className="video-item"
          onMouseEnter={() => handleVideoHover(video.id)}
          onMouseLeave={handleVideoLeave}
          >
            <div
              className="video-thumbnail"
              
            >
              <img src={video.image} alt={video.title} />
              {hoveredVideo === video.id && (
                <video className="hovered-video" autoPlay muted loop>
                  <source src={video.video} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              )}
{/* <div className="card-big-title uppercase" style={{ textAlign: align }}>
              <h2>{video.title}</h2>
              <h2>{video.subTitle}</h2>
            </div>
            <div className="card-big-border-title uppercase" style={{ textAlign: align }}>
            <h2>{video.title}</h2>
              <h2>{video.subTitle}</h2>
            </div> */}
            </div>
            
          </div>
          <div>
            <h3 className="card-title uppercase" style={{ textAlign: align }}>
              {video.title}
            </h3>
            <h4 className="card-subtitle uppercase" style={{ textAlign: align }}>
              {video.subTitle}
            </h4>
            <p className="card-director uppercase" style={{ textAlign: align }}>
              {video.director}
            </p>
          </div>
        </>
      ))}
    </div>
  );
};

export default Cards;
