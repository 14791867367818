import React, { useEffect, useState } from "react";
import "./DirectorForm.css";
import axios from "axios";
import { apiRoute } from "../../services/api";

const DeleteDirector = ({ director }) => {
  const [directorData, setDirectorData] = useState({
    name: "",
    link: "",
    image: null, // Para manejar la carga de la imagen
    reelID: "",
    about: "",
  });
  const [file, setFile] = useState(null);
  const [directorsList, setDirectorsList] = useState([]);
  const [selectedDirector, setSelectedDirector] = useState(null);

  const loadDirectorsList = async () => {
    try {
      const response = await axios.get(apiRoute + "directors");
      setDirectorsList(response.data);
    } catch (error) {
      console.error("Error al obtener la lista de directores:", error);
    }
  };

  useEffect(() => {
    loadDirectorsList();
  }, [selectedDirector]);

  // Manejar cambios en los campos del formulario
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDirectorData({
      ...directorData,
      [name]: value,
    });
  };

  const handleDirectorSelect = (director) => {
    setSelectedDirector(director);
    // Cargar los datos del director seleccionado en el formulario de edición
    setDirectorData({
      name: director.name,
      link: director.link,
      image: null, // Aquí puedes cargar la imagen si lo deseas
      reelID: director.reelID,
      about: director.about,
    });
  };

  // Generar el enlace automáticamente
  const generateLink = () => {
    const formattedName = directorData.name.toLowerCase().replace(/ /g, "-");
    setDirectorData({
      ...directorData,
      link: formattedName,
    });
  };

  // Manejar la carga de la imagen
  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
    if (file) {
    }
  };

  // Manejar el envío del formulario
  // Manejar el envío del formulario
  const handleDelete = async (event) => {
   
      try {
        // Realiza la solicitud HTTP POST al servidor para crear un nuevo director

        const response = await axios.delete(
          apiRoute + "directors/" + selectedDirector.id + "/delete"
        );

        const response2 = await axios.delete(
          apiRoute + "videos/" + selectedDirector.id + "/director/delete"
        );
      
        console.log(response)
        console.log(response2)

        if (response.status === 200) {
          // El director fue creado exitosamente
          console.log("Director creado exitosamente", response.data);
          alert("Director Borrado exitosamente")
          setFile(null)
          setSelectedDirector(null)

          // Aquí puedes redirigir al usuario a una página de éxito o realizar otras acciones.
        }
      } catch (error) {
        // Maneja los errores en caso de que la solicitud falle
        console.error("Error al crear director:", error);
      }
    
  };

  return (
    <>
      {selectedDirector ? (
        <div className="director-form-container">
          <h2 className="form-title">Esta seguro que desea eliminar al director {directorData.name}</h2>
          
          <div className="form-actions">
            <button
              className="form-button form-delete"
              style={{ backgroundColor: "#df0b0b" }}
              onClick={handleDelete}
            >
              Eliminar Director
            </button>
            </div>
          
        </div>
      ) : (
        <div className="director-form-container">
          <h2 className="form-title">Seleccione un director para editar</h2>
          <ul>
            {directorsList &&
              directorsList.map((director) => {
                return (
                  <li
                    className="select-director"
                    key={`director-${director._id}`}
                    onClick={() => handleDirectorSelect(director)}
                  >
                    {director.name}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
};

export default DeleteDirector;
