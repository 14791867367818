// import React, { useState } from "react";
// import "./Cards.css";

// const Cards = ({ slides, bigCard, textAlign }) => {
//   const [onFocus, setOnFocus] = useState(false);
//   return (
//     <div>
//       <div className="card-blur"></div>
//       <a
//         className={bigCard ? "card-container-big" : "card-container"}
//         href="/"
//         onMouseEnter={() => console.log(slides.image)}
//         onMouseLeave={() => setOnFocus(false)}
//       >
//         <img src={slides.image} alt='imagen' />
//         <video src={slides.video} />
//       </a>
//     </div>
//   );
// };
// export default Cards;

import React from "react";
import "./Cards.css"; // Asegúrate de ajustar la ruta al archivo CSS
import { apiRoute } from "../services/api";
import { Link } from 'react-router-dom';

const Cards2 = ({ video }) => {
 
  const href = video.id

  return (
    <div className="video-list">

        <>
          <div key={video.id} className="video-item"
          >
            <a
            href={'music-videos/' + href}
              className="video-thumbnail"
              
            >
              <img src={apiRoute + '/videos/' + video.id + '/thumbnail'} alt={video.title} />
              
            </a>
            
          </div>
          <div>
            <h3 className="card-title uppercase" style={{ textAlign: 'center' }}>
              {video.title}
            </h3>
            <h4 className="card-subtitle uppercase" style={{ textAlign: 'center' }}>
              {video.client}
            </h4>
          </div>
        </>

    </div>
  );
};

export default Cards2;
