import React from "react";
// import ImageSlider from "../components/ImageSlider";
import Header from "../components/Header";
import Drawer from "../components/Drawer";
// import Navbar from "../components/navbar";
// import Cards from "../components/Cards";
// import VideoPrincipal from '../assets/videoPrincipal/SSF_1.mp4'
import logo from '../assets/logo/logoWhite.png'
import MainVideo from "../components/MainVideo";

const Work = () => {
  
  return (
    <div>
      <Header white/>

      <Drawer />
      <MainVideo />
      <div className='contact-background'>
      {/* <img className="contact-logo" src={logo} alt="stereosonique" /> */}
      <div className="contact-social-media">
        
      </div>
    </div>
      {/* <ImageSlider slides={slides2} noArrows /> */}
      {/* <Navbar />
      <div className="separador"></div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
      <Cards videos={slides} align={'left'}/>
      <div>
        <div className="separador" />
      <Cards videos={slides} align={'center'}/>
      </div>
      <Cards videos={slides} align={'right'}/>
      </div> */}
      
    </div>
  );
};

export default Work;
