import React from "react";
import './BackgroundText.css'

const BackgroundText = ({text, size}) =>{
  return(
    <div className='backgroundtext'>
      <span className='backgroundtext-text' style={{ fontWeight:'bold', fontSize: size}}>{text}</span>
    </div>
  )
}

export default BackgroundText;