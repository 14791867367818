import React, { useEffect, useState, useRef } from "react";
import "./DirectorForm.css";
import axios from "axios";
import { apiRoute } from "../../services/api";

function EditMusicVideo({ director }) {
  const [videoData, setVideoData] = useState({
    id: null,
    director_id: null,
    thumbnail: null,
    videoPreview: null, // Para manejar la carga de la imagen
    youtubeLink: "",
    title: "",
    client: "",
    year: "",
    credits: "",
  });
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [directorsList, setDirectorsList] = useState([]);
  const [selectedDirector, setSelectedDirector] = useState([]);
  const [videoList, setVideoList] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  // const [showDirectors, setShowDirectors] = useState(true)
  const [seleccioneDirector, setSeleccioneDirector] = useState(true);
  const [seleccioneVideo, setseleccioneVideo] = useState(false);
  const [reload, setReload] = useState(false);

  const loadDirectorsList = async () => {
    try {
      const response = await axios.get(apiRoute + "directors");
      setDirectorsList(response.data);
    } catch (error) {
      console.error("Error al obtener la lista de directores:", error);
    }
  };

  const loadVideoList = async () => {
    try {
      const response = await axios.get(apiRoute + "videos/get");
      setVideoList(response.data);
      console.log("loadVideoList", response.data);
    } catch (error) {
      console.error("Error al obtener la lista de directores:", error);
    }
  };

  useEffect(() => {
    loadDirectorsList();
    loadVideoList();
  }, []);

  const handleDirectorSelect = (director) => {
    if (videoList) {
      setSelectedDirector(director);
      setSeleccioneDirector(false);
      setseleccioneVideo(true);
    }
  };

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setseleccioneVideo(false);
    setVideoData({
      id: video.id,
      director_id: selectedDirector.id,
      thumbnail: null,
      videoPreview: null, // Para manejar la carga de la imagen
      youtubeLink: video.youtubeLink,
      title: video.title,
      client: video.client,
      year: video.year,
      credits: video.credits,
    });

    console.log(video);
  };

  // Manejar cambios en los campos del formulario
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setVideoData({
      ...videoData,
      [name]: value,
    });
  };

  // Manejar la carga de la imagen
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };
  const handleVideoChange = (event) => {
    setVideo(event.target.files[0]);
  };

  // Manejar el envío del formulario
  // Manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    // Crear un objeto FormData
    const formData = new FormData();
    // Agregar cada campo de videoData al formData
    formData.append("director_id", selectedDirector.id);
    formData.append("youtubeLink", videoData.youtubeLink);
    formData.append("title", videoData.title);
    formData.append("client", videoData.client);
    formData.append("year", videoData.year);
    formData.append("credits", videoData.credits);

    const formData2 = new FormData();
    formData2.append("thumbnail", image);

    const formData3 = new FormData();
    formData3.append("videoPreview", video);

    // Ahora puedes usar formData para enviar una solicitud HTTP

    if (videoData.youtubeLink === "" || videoData.title === "") {
      alert(
        "Los campos de enlace de Youtube y titulo deben tener algo escrito"
      );
      setLoading(false)
    } else {
      try {
        // Realiza la solicitud HTTP POST al servidor para crear un nuevo director
        console.log(formData);
        const response = await axios.patch(
          apiRoute + "videos/" + videoData.id + "/patch",
          formData,
          {
            headers: {
              "Content-Type": "application/json", // Indica que estás enviando datos multipart/form-data
            },
          }
        );

        if (image) {
          await axios.patch(
            apiRoute + "videos/" + videoData.id + "/patch/thumbnail",
            formData2,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Indica que estás enviando datos multipart/form-data
              },
            }
          );
        }

        if (video) {
          await axios.patch(
            apiRoute + "videos/" + videoData.id + "/patch/videoPreview",
            formData3,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Indica que estás enviando datos multipart/form-data
              },
            }
          );
        }

        if (response.status === 200) {
          // El director fue creado exitosamente
          console.log("Director creado exitosamente", response.data);
          alert("El video a sido editado Exitosamente");
          setReload(!reload);
          setVideoData({
            id: null,
            director_id: null,
            thumbnail: null,
            videoPreview: null, // Para manejar la carga de la imagen
            youtubeLink: "",
            title: "",
            client: "",
            year: "",
            credits: "",
          });
          setImage(null);
          setVideo(null);
          setSelectedDirector([]);
          setSeleccioneDirector(true);
          setSelectedVideo(null);
          loadVideoList();
          setLoading(false)

          // Aquí puedes redirigir al usuario a una página de éxito o realizar otras acciones.
        }
      } catch (error) {
        // Maneja los errores en caso de que la solicitud falle
        console.error("Error al crear Video:", error);
        alert("No se ah podido editar el video, intentelo de nuevo mas tarde");
        console.log(selectedDirector, image, video, videoData);
        setLoading(false)
      }
    }
  };

  return (
    <>
      {selectedVideo ? (
        <div className="director-form-container">
          <h2 className="form-title">Editar Video Musical</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="thumbnail" className="form-label">
                Thumbnail:
              </label>
              <input
                type="file"
                id="thumbnail"
                accept="image/*"
                name="thumbnail"
                className="form-input"
                onChange={handleImageChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="videoPreview" className="form-label">
                Video Preview:
              </label>
              <input
                type="file"
                id="videoPreview"
                accept="video/*"
                name="videoPreview"
                className="form-input"
                onChange={handleVideoChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="youtubeLink" className="form-label">
                Enlace de YouTube:
              </label>
              <input
                type="text"
                id="youtubeLink"
                name="youtubeLink"
                className="form-input"
                value={videoData.youtubeLink}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="title" className="form-label">
                Título:
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="form-input"
                value={videoData.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="client" className="form-label">
                Cliente:
              </label>
              <input
                type="text"
                id="client"
                name="client"
                className="form-input"
                value={videoData.client}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="year" className="form-label">
                Año:
              </label>
              <input
                type="number"
                id="year"
                name="year"
                className="form-input"
                value={videoData.year}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="credits" className="form-label">
                Creditos:
              </label>
              <textarea
                id="summernote"
                name="credits"
                className="form-input"
                value={videoData.credits}
                onChange={handleInputChange}
              />
            </div>

            {/* Puedes agregar más campos aquí según tus necesidades */}
            <div className="form-actions">
              {loading ? (
                <button className="form-button" disabled>Loading...</button>
              ) : (
                <button type="submit" className="form-button">
                  Editar Video
                </button>
              )}
            </div>
          </form>
        </div>
      ) : null}

      {seleccioneDirector ? (
        <div className="director-form-container">
          <h2 className="form-title">Seleccione un director para editar</h2>
          <ul>
            {directorsList.map((director) => {
              return (
                <li
                  className="select-director"
                  key={`director-${director.id}`}
                  onClick={() => handleDirectorSelect(director)}
                >
                  {director.name}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}

      {seleccioneVideo ? (
        <div className="director-form-container">
          <h2 className="form-title">Seleccione un video para editar</h2>
          <ul>
            {videoList.map((video) => {
              if (video.director_id === selectedDirector.id) {
                return (
                  <li
                    className="select-director"
                    key={video.id}
                    onClick={() => handleVideoSelect(video)}
                  >
                    {video.title}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
}

export default EditMusicVideo;
