import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";

// import Carrousel from "./components/Carrousel";
// import Contact from "./pages/contact";
// import Directors from "./pages/directors";
// import './css/index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Work from "./pages/work";

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
  )
  