import React, { useState } from "react";
import "./Drawer.css";
import { Link } from 'react-router-dom';

const Drawer = () => {
  const [drawer, setDrawer] = useState(false);
  return (
    
    <div>
      <div
        className={`site-hamburger ${drawer ? "clicked" : ""}`}
        onClick={() => setDrawer(!drawer)}
      />
      <div className={`container ${drawer ? "expand-container" : ""}`}>
        <ul className="list-drawer">
          <li className="item-drawer">
            <Link to="/" className="text-drawer" onClick={() => setDrawer(!drawer)} >
              Home
            </Link>
          </li>
          <li className="item-drawer">
            <Link to="/directors" className="text-drawer" onClick={() => setDrawer(!drawer)}>
              Directors
            </Link>
          </li>
          {/* <li className="item-drawer">
            <a href="/news" className="text-drawer" >
              News
            </a>
          </li> */}
          {/* <li className="item-drawer">
            <a href="/about" className="text-drawer" >
              About
            </a>
          </li> */}
          <li className="item-drawer">
            <Link to="/contact" className="text-drawer" onClick={() => setDrawer(!drawer)}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className={`container2 ${drawer ? "expand-container" : ""}`}></div>

      
    </div>
  );
};

export default Drawer;
