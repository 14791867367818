import axios from "axios";

export const apiRoute = "https://api.stereosoniquefilms.com/api/";

// Crear un nuevo director
export const createDirector = async (directorData) => {
  try {
    const response = await axios.post(
      apiRoute + "directors/create",
      directorData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al crear el director:", error);
    throw error;
  }
};

// Obtener todos los directores
export const getAllDirectors = async () => {
  try {
    const response = await axios.get(apiRoute + "directors");
    return response.data;
  } catch (error) {
    console.error("Error al obtener la lista de directores:", error);
    throw error;
  }
};

export const getImageById = async (directorId) => {
  try {
    const response = await axios.get(apiRoute + `directors/${directorId}/imagen`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la imagen: ", error);
    throw error;
  }
};
// Obtener un director por su ID
export const getDirectorById = async (directorId) => {
  try {
    const response = await axios.get(`/api/directors/${directorId}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el director:", error);
    throw error;
  }
};

// Actualizar un director por su ID
export const updateDirectorById = async (directorId, updatedData) => {
  try {
    const response = await axios.put(
      `/api/directors/${directorId}/update`,
      updatedData
    );
    return response.data;
  } catch (error) {
    console.error("Error al actualizar el director:", error);
    throw error;
  }
};

// Eliminar un director por su ID
export const deleteDirectorById = async (directorId) => {
  try {
    const response = await axios.delete(`/api/directors/${directorId}/delete`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el director:", error);
    throw error;
  }
};
